import { Box, Typography } from '@mui/material';
import styles from './PredictionCard.module.scss';

export default function ColumnCard(props: { title: string; adornment?: React.ReactNode; children?: React.ReactNode }) {
  return (
    <Box className={styles.column_card}>
      <Box className={styles.column_card_title}>
        <div style={{ flex: 1 }} />
        <Typography flex={1} variant='h6' textAlign='center'>
          {props.title}
        </Typography>
        <Box flex={1} display='flex' justifyContent='center' alignSelf='center'>
          {props.adornment}
        </Box>
      </Box>
      {props.children}
    </Box>
  );
}
