import React from 'react';
import Box from '@mui/material/Box';
import { ResponsiveDrawer } from '../components/ResponsiveDrawer';
import { ImageCard } from '../components/ImageCard';
import { ActivityList } from '../components/LiveView/ActivityList';
import { useAuth0 } from '@auth0/auth0-react';
import { Socket, io } from 'socket.io-client';
import { UserContext } from '../UserContext';
import { RefreshMode } from '../components/LiveView/RefreshMode';
import { CurrentActivity } from '../components/LiveView/CurrentActivity';
import { Card, useMediaQuery } from '@mui/material';
import { ApiCallState, ICapturerEvents, blobToDataURL } from '../api';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { RESPONSIVE_DRAWER_HEIGHT } from '../Constants';
import { ActivityService, LicensePlateDTO, PredictionDTO } from '../api/generated';
import { useSnackbar } from '../components/SnackbarProvider';
import { useTheme } from '@mui/material/styles';
import PredictionCard from '../components/Prediction/PredictionCard';

const liveSocket: Socket = io({
  path: '/api/live.socket/',
  autoConnect: false,
});
liveSocket.on('connect', () => {
  console.log('WebSocket connection established');
});
liveSocket.on('disconnect', () => {
  console.log('WebSocket connection disconnected');
});
liveSocket.on('error', (error) => {
  console.log('WebSocket connection closed');
});
liveSocket.on('connect_error', (error) => {
  console.log('WebSocket connection closed');
});

export class Activity {
  private started: boolean = true;
  private uploaded: boolean = false;
  private captured: boolean = false;
  private stitched: boolean = false;

  private _licenseplate: LicensePlateDTO | undefined = undefined;
  private _licenseplate_retries: number = 0;
  private _is_valid: boolean | undefined = undefined;
  private _prediction: ApiCallState<PredictionDTO> = { result: undefined };
  private _prediction_retries: number = 0;

  constructor(readonly capture_start_at: string, readonly jobsiteId: string) {}

  get recording() {
    return this.started && !this.uploaded;
  }

  set recording(recording: boolean) {
    this.started = recording;
  }

  get uploading() {
    return this.started && this.uploaded && !this.captured;
  }

  set uploading(uploading: boolean) {
    this.started = uploading ? true : this.started;
    this.uploaded = uploading;
  }

  get processing() {
    return this.started && this.uploaded && this.captured && !this.stitched;
  }

  set processing(processing: boolean) {
    this.started = processing ? true : this.started;
    this.uploaded = processing ? true : this.uploaded;
    this.captured = processing;
  }

  get finished() {
    return this.stitched;
  }

  set finished(finished: boolean) {
    this.started = finished ? true : this.started;
    this.uploaded = finished ? true : this.uploaded;
    this.captured = finished ? true : this.captured;
    this.stitched = finished;
  }

  get licensePlateText() {
    return this._licenseplate?.ocr_text?.toUpperCase() || '-';
  }

  get licensePlate() {
    return this._licenseplate;
  }

  set licensePlate(lp: LicensePlateDTO | undefined) {
    this._licenseplate = lp;
  }

  async fetchLicensePlate() {
    if (this._licenseplate === undefined && this._licenseplate_retries < 3) {
      this._licenseplate_retries++;
      const response = await ActivityService.activityControllerGetActivityLicensePlate({
        path: {
          jobsiteId: this.jobsiteId,
          capture_start_at: this.capture_start_at,
        },
      });
      if (response.error || !response.data) {
        if (response.error === 'Not Found') {
          return;
        } else {
          console.error(response.error);
          return;
        }
      }
      this._licenseplate = response.data;
    }
  }

  get is_valid() {
    // undefined => not checked yet
    return this._is_valid;
  }

  async check_validity() {
    this._is_valid =
      (
        await ActivityService.activityControllerGetActivity({
          path: {
            jobsiteId: this.jobsiteId,
            capture_start_at: this.capture_start_at,
          },
        })
      )?.data?.motion_reversed || false;
  }

  get prediction() {
    return this._prediction;
  }

  set prediction(pred: ApiCallState<PredictionDTO>) {
    this._prediction = pred;
  }

  async fetchPrediction() {
    if (this._prediction.result === undefined && this._prediction_retries < 3) {
      this._prediction_retries++;
      const response = await ActivityService.activityControllerGetActivityPrediction({
        path: {
          jobsiteId: this.jobsiteId,
          capture_start_at: this.capture_start_at,
        },
      });
      if (response.error || !response.data) {
        if (response.error === 'Not Found') {
          return;
        } else {
          console.error(response.error);
          return;
        }
      }
      this._prediction = { result: response.data };
    }
  }
}

export function LiveView() {
  const { getAccessTokenSilently } = useAuth0();
  const { user } = React.useContext(UserContext);
  const { showSnackbar } = useSnackbar();
  const theme = useTheme();
  const padding: string = '1rem';

  const [activities, dispatch] = React.useReducer(activitiesReducer, { result: undefined });
  const [debouncedActivities, setDebouncedActivities] = React.useState<ApiCallState<Activity[]>>({ result: undefined });
  const [currentActivity, setCurrentActivity] = React.useState<ApiCallState<Activity>>({ result: undefined });
  const [mode, setMode] = React.useState<'live' | 'manual'>('live');
  const [image, setImage] = React.useState<ApiCallState<string>>({});
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  liveSocket.on('event', async (data: string) => {
    const event = JSON.parse(data);
    dispatch(event);
  });

  function activitiesReducer(state: ApiCallState<Activity[]>, action: ICapturerEvents): ApiCallState<Activity[]> {
    if (action.type === 'license_plate_read') return state;

    // only regard messages less than 1 day old
    const date = new Date(action.capture_start_at);
    const now = new Date();
    if (now.getTime() - date.getTime() > 24 * 60 * 60 * 1000) return state;

    if (state.result === undefined) {
      state.result = [];
    }

    let index = state.result?.findIndex((activity) => activity.capture_start_at === action.capture_start_at);
    if (index === -1) {
      state.result?.unshift(new Activity(action.capture_start_at, user?.preferredJobsite?.id || ''));
      index = 0;
    } else if (state.result[index].finished) {
      return state;
    }

    switch (action.type) {
      case 'activity_started':
        state.result[index].recording = true;
        break;
      case 'activity_ended':
        state.result[index].uploading = true;
        break;
      case 'datapoint_uploaded':
        state.result[index].processing = true;
        break;
      case 'stitching_finished':
        state.result[index].check_validity();
        break;
      case 'classification_finished':
        state.result[index].fetchPrediction();
        state.result[index].finished = true;
        break;
    }
    // sort activities by capture_start_at (latest first)
    state.result?.sort((a, b) => {
      return new Date(b.capture_start_at).getTime() - new Date(a.capture_start_at).getTime();
    });

    return { result: state.result };
  }

  // subscribe to live socket on mount
  React.useEffect(() => {
    (async () => {
      return await getAccessTokenSilently();
    })().then((token) => {
      liveSocket.auth = { token: token };
      liveSocket.io.opts.query = { jobsiteId: user?.preferredJobsite?.id };
      liveSocket.connect();
    });
    return () => {
      liveSocket.disconnect();
    };
  }, []);

  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedActivities(activities);
    }, 1000); // delay in ms

    return () => {
      clearTimeout(handler);
    };
  }, [JSON.stringify(activities)]);

  // set latestActivity, pull licenseplates and get feedback
  React.useEffect(() => {
    (async () => {
      if (activities.result === undefined) return;

      // check validity of activities where stitching is finished
      const validityPromises = activities.result.map(async (activity) => {
        if (activity.is_valid === undefined && activity.finished) {
          await activity.check_validity();
        }
      });
      await Promise.all(validityPromises);

      if (mode === 'live') {
        const finishedActivities = activities.result.filter((activity) => activity.finished);
        if (
          finishedActivities.length > 0 &&
          finishedActivities[0].capture_start_at !== currentActivity.result?.capture_start_at &&
          finishedActivities[0].is_valid
        ) {
          setCurrentActivity({ result: finishedActivities[0] });
        }
      }

      await Promise.all(
        activities.result.map(async (activity) => {
          // await activity.fetchPrediction(user?.preferredJobsite?.id || '');
          await activity.fetchLicensePlate();
        })
      );
    })();
  }, [JSON.stringify(debouncedActivities), mode]);

  function handleRowSelect(activity: ApiCallState<Activity>) {
    setCurrentActivity(activity);
    setMode('manual');
  }

  async function pictureDownload(capture_start_at: string | undefined): Promise<string> {
    if (!capture_start_at) return '';
    const response = await ActivityService.activityControllerGetActivityImage({
      path: {
        jobsiteId: user?.preferredJobsite?.id || '',
        capture_start_at: capture_start_at,
      },
    });

    if (response.error) {
      console.error(response.error);
      showSnackbar('Fehler beim Laden des Bildes', 'error');
      return '';
    }

    const dataUrl = await blobToDataURL(response?.data as Blob);
    return dataUrl;
  }

  React.useEffect(() => {
    let active = true;
    (async () => {
      setImage({});
      let img = '';
      try {
        img = await pictureDownload(currentActivity.result?.capture_start_at);
      } catch (err) {
        console.error(err);
      }
      return img;
    })()
      .then((img) => {
        if (!img || !active) return;
        setImage({ result: img });
      })
      .catch((err) => {
        console.error({ error: `${err.message}` });
      });

    return () => {
      active = false;
    };
  }, [currentActivity]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        overflow: isSmallScreen ? 'scroll' : 'hidden',
        top: RESPONSIVE_DRAWER_HEIGHT,
      }}
    >
      <Box sx={{ height: RESPONSIVE_DRAWER_HEIGHT }}>
        <ResponsiveDrawer />
      </Box>
      <Grid
        container
        spacing={2}
        direction={isSmallScreen ? 'column' : 'row'}
        sx={{
          flex: 1,
          padding: padding,
        }}
      >
        {/* Image and Prediction Column */}
        <Grid
          xs={12}
          md={8}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: isSmallScreen ? 'auto' : `calc(100vh - ${RESPONSIVE_DRAWER_HEIGHT} - 2 * ${padding})`,
            position: isSmallScreen ? 'relative' : 'static',
            top: isSmallScreen ? RESPONSIVE_DRAWER_HEIGHT : 'auto',
          }}
        >
          <Grid container direction='column' spacing={2} sx={{ flex: 1 }}>
            <Grid sx={{ flex: 1, display: 'flex' }}>
              <Box sx={{ flex: 1 }}>
                <ImageCard image={image} />
              </Box>
            </Grid>
            <Grid sx={{ flex: 1, display: 'flex' }}>
              <Box sx={{ flex: 1 }}>
                <PredictionCard prediction={currentActivity.result?.prediction || { result: undefined }} />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        {/* LiveProgress, CurrentActivity and ActivityList Column */}
        <Grid
          xs={12}
          md={4}
          sx={{
            height: isSmallScreen ? 'auto' : '100%',
            display: 'flex',
            position: isSmallScreen ? 'relative' : 'static',
            top: isSmallScreen ? RESPONSIVE_DRAWER_HEIGHT : 'auto',
          }}
        >
          <Box sx={{ flex: 1, width: '100%' }}>
            <Card
              sx={{
                flex: 1,
                borderRadius: '10px',
                height: '100%',
                overflow: 'scroll',
              }}
              elevation={8}
            >
              <RefreshMode mode={mode} onModeChange={setMode} />
              <CurrentActivity activity={currentActivity} />
              <ActivityList
                activities={activities}
                currentActivity={currentActivity}
                onRowSelect={handleRowSelect}
                onFetchActivities={dispatch}
              />
            </Card>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
