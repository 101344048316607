import { ApiCallState } from '../../api';
import { PredictionDTO } from '../../api/generated';
import { Box, Card, Container, Divider, Typography } from '@mui/material';
import { ApiLoadingGuard } from '../ApiLoadingGuard';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import SettingsIcon from '@mui/icons-material/Settings';
import React from 'react';
import styles from './PredictionCard.module.scss';
import ColumnCard from './ColumnCard';
import AVVColumn from './AVV';

export const prediction_error_display = (): JSX.Element => (
  <Container sx={{ display: 'flex', flexDirection: 'column', margin: 'auto' }}>
    <ErrorOutlineIcon fontSize='large' sx={{ margin: 'auto' }} />
    <Typography variant='h6' textAlign={'center'} sx={{ margin: 'auto' }}>
      Es konnte keine Prognose erstellt werden
    </Typography>
  </Container>
);

export interface PredictionCardProps {
  prediction: ApiCallState<PredictionDTO>;
}

const AttributesColumn: React.FC = () => (
  <ColumnCard title='Attribute'>
    {['Fremdkörper', 'Mutterboden', 'Kantenlänge'].map((elem) => (
      <Card key={elem} variant='outlined' className={styles.attribute_chip}>
        <Typography noWrap>{elem}</Typography>
      </Card>
    ))}
  </ColumnCard>
);

const CategoryColumn: React.FC = () => <ColumnCard title='Sorte' adornment={<SettingsIcon />} />;

const DisabledOverlay: React.FC = () => (
  <Box
    sx={{
      position: 'absolute',
      top: 0,
      left: 'calc((100% / 3))',
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0,0,0,0.3)',
      backdropFilter: 'blur(2px)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 10,
    }}
  >
    <Typography
      variant='h5'
      color='white'
      sx={{
        textAlign: 'center',
        fontWeight: 'bold',
      }}
    >
      Bald verfügbar
    </Typography>
  </Box>
);

export default function PredictionCard(props: PredictionCardProps) {
  const cardPadding = '0.75rem';
  return (
    <Card
      elevation={8}
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        width: '100%',
        height: `100%`,
        borderRadius: '10px',
        position: 'relative',
      }}
    >
      <ApiLoadingGuard apiStates={[props.prediction]} error_display={prediction_error_display}>
        <Box
          flex={1}
          display='flex'
          flexDirection='row'
          height={`calc(100% - 2* ${cardPadding})`}
          width='100%'
          gap={cardPadding}
          padding={cardPadding}
        >
          <AVVColumn predictionOutput={props.prediction.result?.output} />
          <Divider orientation='vertical' flexItem />
          <AttributesColumn />
          <Divider orientation='vertical' flexItem />
          <CategoryColumn />
          <DisabledOverlay />
        </Box>
      </ApiLoadingGuard>
    </Card>
  );
}
