import { Card, Typography, Stack, Tooltip, Paper, Divider, Box, IconButton, Grow } from '@mui/material';
import { useState } from 'react';
import { OutputSchemaDTO } from '../../api/generated';
import { getGermanAVVClassNameFromAvvNumber } from '../../Constants';
import ColumnCard from './ColumnCard';
import InfoIcon from '@mui/icons-material/Info';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './PredictionCard.module.scss';

type ConfidenceLevel = 'high' | 'medium' | 'low';

function AVVFieldTooltip() {
  function TooltipColorItem(props: { confidenceLevel: ConfidenceLevel; text: string }) {
    const { confidenceLevel, text } = props;
    return (
      <Stack direction='row' spacing={2} alignItems={'center'}>
        <Card
          className={`${styles.avv_chip} ${styles[confidenceLevel]}`}
          sx={{ minWidth: 0, height: '0.75rem', width: '0.75rem', flexShrink: 0 }}
        />
        <Typography color='black'>{text}</Typography>
      </Stack>
    );
  }

  return (
    <Tooltip
      title={
        <Paper
          elevation={10}
          sx={{
            p: 2,
            bgcolor: 'common.white',
            color: 'common.black',
            borderRadius: '10px',
          }}
        >
          <Stack direction='column' spacing={2} sx={{ width: '220px' }}>
            <Typography variant='body1'>Prognose der AVV-Nummer anhand des aufgenommenen Bildes</Typography>
            <Divider />
            <TooltipColorItem confidenceLevel='high' text='Sichere Prognose' />
            <TooltipColorItem confidenceLevel='medium' text='Möglicherweise ungenau' />
            <TooltipColorItem confidenceLevel='low' text='Keine Zuweisung möglich' />
          </Stack>
        </Paper>
      }
      placement='top'
      disableFocusListener
      enterTouchDelay={100}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: 'transparent',
          },
        },
      }}
    >
      <InfoIcon />
    </Tooltip>
  );
}

interface AVVChipProps {
  primaryText: string;
  secondaryText: string | undefined;
  confidenceLevel: ConfidenceLevel;
}

function AVVChip(props: { primaryText: string; secondaryText: string; confidenceLevel: ConfidenceLevel }) {
  const { primaryText, secondaryText, confidenceLevel } = props;
  return (
    <Card className={`${styles.avv_chip} ${styles[confidenceLevel]}`}>
      <Typography
        align='center'
        variant='inherit'
        sx={{
          fontWeight: 700,
        }}
      >
        {primaryText}
      </Typography>
      {secondaryText && (
        <Typography align='center' variant='inherit'>
          {secondaryText}
        </Typography>
      )}
    </Card>
  );
}

export const useAVVPrediction = (predictionOutput: OutputSchemaDTO[] | undefined) => {
  const CONFIDENCE_RANGES = {
    high: [0.9, 1],
    medium: [0.5, 0.9],
    low: [0, 0.5],
  };

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => setIsExpanded(!isExpanded);

  if (!predictionOutput || predictionOutput.length === 0) {
    return { mainChip: null, expandableChips: null, isExpanded, toggleExpanded };
  }

  const sortedPredictions = predictionOutput
    .map((x) => ({
      ...x,
      display_name: getGermanAVVClassNameFromAvvNumber(x.avv, x.class_name),
    }))
    .sort((a, b) => b.probability - a.probability);

  const getConfidenceLevel = (probability: number): ConfidenceLevel => {
    if (probability >= CONFIDENCE_RANGES.high[0]) return 'high';
    if (probability >= CONFIDENCE_RANGES.medium[0]) return 'medium';
    return 'low';
  };

  const confidenceLevel = getConfidenceLevel(sortedPredictions[0].probability);

  const mainChip: AVVChipProps = {
    primaryText: confidenceLevel !== 'low' ? sortedPredictions[0].avv : 'Keine eindeutige Zuweisung möglich',
    secondaryText: confidenceLevel !== 'low' ? sortedPredictions[0].display_name : undefined,
    confidenceLevel,
  };

  const expandableChips: AVVChipProps[] | null =
    confidenceLevel === 'low'
      ? sortedPredictions.slice(0, 2).map((x) => ({
          primaryText: x.avv,
          secondaryText: x.display_name,
          confidenceLevel: 'low' as ConfidenceLevel,
        }))
      : null;

  return {
    mainChip,
    expandableChips,
    isExpanded,
    toggleExpanded,
  };
};

interface AVVFieldProps {
  predictionOutput: OutputSchemaDTO[] | undefined;
}

export default function AVVColumn(props: AVVFieldProps) {
  const { predictionOutput } = props;
  const { mainChip, expandableChips, isExpanded, toggleExpanded } = useAVVPrediction(predictionOutput);

  if (!mainChip) return null;

  return (
    <ColumnCard title='AVV' adornment={<AVVFieldTooltip />}>
      <AVVChip
        primaryText={mainChip.primaryText}
        secondaryText={mainChip.secondaryText || ''}
        confidenceLevel={mainChip.confidenceLevel}
      />
      {expandableChips && (
        <Box width='100%' display='flex' flexDirection='column' alignItems='center'>
          <IconButton onClick={toggleExpanded}>{isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
          <Grow in={isExpanded}>
            <Stack spacing={1} alignItems='center' sx={{ width: '100%' }}>
              {expandableChips.map((chip, index) => (
                <AVVChip
                  key={index}
                  primaryText={chip.primaryText}
                  secondaryText={chip.secondaryText || ''}
                  confidenceLevel={chip.confidenceLevel}
                />
              ))}
            </Stack>
          </Grow>
        </Box>
      )}
    </ColumnCard>
  );
}
