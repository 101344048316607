export const RESPONSIVE_DRAWER_HEIGHT: number = 64; // The unit is px
export const RESPONSIVE_DRAWER_WIDTH: number = 240; // The unit is px
export const DETAILVIEW_TOOLBAR_HEIGHT: number = 3; // The unit is rem

const AVV_NUMBER_TO_GERMAN: Record<string, string> = {
  '170101': 'Beton',
  '170102': 'Ziegel',
  '170103': 'Fliesen und Keramik',
  '170107': 'Gemischter Bauschutt',
  '170201': 'Holz',
  '170302': 'Bitumen',
  '170504': 'Boden und Steine',
  '170508': 'Gleisschotter',
} as const;

/**
 * Get the german translation for the given AVV number
 * @param avvNumber AVV number
 * @param class_name English class name for the AVV number
 * @returns German translation OR undefined if the AVV number does not exist
 */
export const getGermanAVVClassNameFromAvvNumber = (avvNumber: string, class_name: string): string | undefined => {
  switch (class_name.toLowerCase()) {
    case 'covered':
      return 'Beplant';
    case 'empty':
      return 'Leer';
    case 'truck parts':
      return 'Fahrzeug';
    default:
      return AVV_NUMBER_TO_GERMAN[avvNumber];
  }
};
